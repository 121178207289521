.image-container {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
  background-color: white;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 30px;
  box-shadow: 1px 2px 2px #00000050;
  text-align: center;
  cursor: pointer;
}

.icon-round {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50px;
  border: 1px solid #00000040;
  position: relative;
  top: -4px;
  left: 82px;
  margin-bottom: 5px;
  text-align: center;
  cursor: pointer;
  box-shadow: 1px 2px 2px #00000050;
}

.add {
    width: 100% !important;
    box-shadow: none;
    border: 2px dashed #eeeeee;
}
